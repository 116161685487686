import { AxiosResponse } from "axios";

import { BaseService } from "libs/services";
import { Sell } from "modules/up-down-sell/models";

type FetchSells = {
  statusCode: number;
  message: string;
  data: Array<Sell>;
};

export class UpDownSellService extends BaseService {
  private ADMIN_API_ROUTE = this.ADMIN_API_HOST;

  static _instance: UpDownSellService;

  constructor() {
    super();

    if (UpDownSellService._instance) {
      throw Error("Unable to create an instance of UpDownSellService");
    }
  }

  static getInstance(): UpDownSellService {
    if (!UpDownSellService._instance) {
      UpDownSellService._instance = new UpDownSellService();
    }

    return UpDownSellService._instance;
  }

  public fetchSells = (): Promise<AxiosResponse<FetchSells>> => this.get(`${this.ADMIN_API_ROUTE}/company-contract/history-update`);
}
