export enum RegionalManagementActionTypes {
  FETCH_COUNTRIES = "[REGIONAL_MANAGEMENT_MODULE] FETCH_COUNTRIES",
  FETCH_COUNTRIES_SUCCESS = "[REGIONAL_MANAGEMENT_MODULE] FETCH_COUNTRIES_SUCCESS",
  FETCH_COUNTRIES_ERROR = "[REGIONAL_MANAGEMENT_MODULE] FETCH_COUNTRIES_ERROR",

  CREATE_COUNTRY = "[REGIONAL_MANAGEMENT_MODULE] CREATE_COUNTRY",
  CREATE_COUNTRY_SUCCESS = "[REGIONAL_MANAGEMENT_MODULE] CREATE_COUNTRY_SUCCESS",
  CREATE_COUNTRY_ERROR = "[REGIONAL_MANAGEMENT_MODULE] CREATE_COUNTRY_ERROR",

  FETCH_CITIES = "[REGIONAL_MANAGEMENT_MODULE] FETCH_CITIES",
  FETCH_CITIES_SUCCESS = "[REGIONAL_MANAGEMENT_MODULE] FETCH_CITIES_SUCCESS",
  FETCH_CITIES_ERROR = "[REGIONAL_MANAGEMENT_MODULE] FETCH_CITIES_ERROR",

  FETCH_SINGLE_CITY = "[REGIONAL_MANAGEMENT_MODULE] FETCH_SINGLE_CITY",
  FETCH_SINGLE_CITY_SUCCESS = "[REGIONAL_MANAGEMENT_MODULE] FETCH_SINGLE_CITY_SUCCESS",
  FETCH_SINGLE_CITY_ERROR = "[REGIONAL_MANAGEMENT_MODULE] FETCH_SINGLE_CITY_ERROR",

  CLEAN_SINGLE_CITY = "[REGIONAL_MANAGEMENT_MODULE] CLEAN_SINGLE_CITY",

  CREATE_CITY = "[REGIONAL_MANAGEMENT_MODULE] CREATE_CITY",
  CREATE_CITY_SUCCESS = "[REGIONAL_MANAGEMENT_MODULE] CREATE_CITY_SUCCESS",
  CREATE_CITY_ERROR = "[REGIONAL_MANAGEMENT_MODULE] CREATE_CITY_ERROR",

  REMOVE_CITY = "[REGIONAL_MANAGEMENT_MODULE] REMOVE_CITY",
  REMOVE_CITY_SUCCESS = "[REGIONAL_MANAGEMENT_MODULE] REMOVE_CITY_SUCCESS",
  REMOVE_CITY_ERROR = "[REGIONAL_MANAGEMENT_MODULE] REMOVE_CITY_ERROR",

  EDIT_CITY = "[REGIONAL_MANAGEMENT_MODULE] EDIT_CITY",
  EDIT_CITY_SUCCESS = "[REGIONAL_MANAGEMENT_MODULE] EDIT_CITY_SUCCESS",
  EDIT_CITY_ERROR = "[REGIONAL_MANAGEMENT_MODULE] EDIT_CITY_ERROR",
}
