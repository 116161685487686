export enum PackageManagementActionTypes {
  FETCH_PACKAGES = "[PACKAGE_MANAGEMENT_MODULE] FETCH_PACKAGES",
  FETCH_PACKAGES_SUCCESS = "[PACKAGE_MANAGEMENT_MODULE] FETCH_PACKAGES_SUCCESS",
  FETCH_PACKAGES_ERROR = "[PACKAGE_MANAGEMENT_MODULE] FETCH_PACKAGES_ERROR",

  FETCH_SINGLE_PACKAGE = "[PACKAGE_MANAGEMENT_MODULE] FETCH_SINGLE_PACKAGE",
  FETCH_SINGLE_PACKAGE_SUCCESS = "[PACKAGE_MANAGEMENT_MODULE] FETCH_SINGLE_PACKAGE_SUCCESS",
  FETCH_SINGLE_PACKAGE_ERROR = "[PACKAGE_MANAGEMENT_MODULE] FETCH_SINGLE_PACKAGE_ERROR",

  REMOVE_PACKAGE = "[PACKAGE_MANAGEMENT_MODULE] REMOVE_PACKAGE",
  REMOVE_PACKAGE_SUCCESS = "[PACKAGE_MANAGEMENT_MODULE] REMOVE_PACKAGE_SUCCESS",
  REMOVE_PACKAGE_ERROR = "[PACKAGE_MANAGEMENT_MODULE] REMOVE_PACKAGE_ERROR",

  CREATE_PACKAGE = "[PACKAGE_MANAGEMENT_MODULE] CREATE_PACKAGE",
  CREATE_PACKAGE_SUCCESS = "[PACKAGE_MANAGEMENT_MODULE] CREATE_PACKAGE_SUCCESS",
  CREATE_PACKAGE_ERROR = "[PACKAGE_MANAGEMENT_MODULE] CREATE_PACKAGE_ERROR",

  EDIT_PACKAGE = "[PACKAGE_MANAGEMENT_MODULE] EDIT_PACKAGE",
  EDIT_PACKAGE_SUCCESS = "[PACKAGE_MANAGEMENT_MODULE] EDIT_PACKAGE_SUCCESS",
  EDIT_PACKAGE_ERROR = "[PACKAGE_MANAGEMENT_MODULE] EDIT_PACKAGE_ERROR",
}
