import { SagaIterator } from "redux-saga";
import { put, call } from "redux-saga/effects";

import { UpDownSellService } from "modules/up-down-sell/services";
import { fetchSellsSuccess, fetchSellsError } from "modules/up-down-sell/store/actions/actionCreators";

const upDownSellsService = UpDownSellService.getInstance();

export function* fetchSellsWorker(): SagaIterator {
  try {
    const { data } = yield call(upDownSellsService.fetchSells);
    yield put(fetchSellsSuccess(data.data));
  } catch (error) {
    yield put(fetchSellsError(error.response.data.message));
  }
}
