export interface Limits {
  active_bots_limit: number | null;
  active_integrations_limit: number | null;
  connected_calendar_limit: number | null;
  domain_allow_listing: string[] | null;
  live_chat_agents_limit: number | null;
}

export enum LimitsKeys {
  ActiveBotsLimit = "active_bots_limit",
  ActiveIntegrationsLimit = "active_integrations_limit",
  ConnectedCalendarLimit = "connected_calendar_limit",
  DomainAllowListing = "domain_allow_listing",
  LivechatAgentsLimit = "live_chat_agents_limit"
}

export enum FieldTypes {
  NUMBER = "number",
  TEXT = "string",
  DROPDOWN = "dropdown",
  ARRAY= "array"
}

export const getLimitsBlocks = () => [
  {
    groups: [
      {
        groupName: "Account Limits:",
        items: [
          {
            label: "Active Bots",
            name: LimitsKeys.ActiveBotsLimit,
            fieldType: FieldTypes.NUMBER,
            className: "",
            bubbleMessage: "It is the maximum number of bots that can be active at the same time.",
            description: "Currently active bots:"
          },
          {
            label: "Connected Calendars",
            name: LimitsKeys.ConnectedCalendarLimit,
            fieldType: FieldTypes.NUMBER,
            className: "",
            bubbleMessage: "It is the maximum number of calendars that can be connected to the platform.",
            description: "Currently connected calendars:"
          },
          {
            label: "LiveChats Agents / Seats",
            name: LimitsKeys.LivechatAgentsLimit,
            fieldType: FieldTypes.NUMBER,
            className: "",
            bubbleMessage: "It is the maximum number of agents that can be active at the same time.",
            description: "Currently LC agents / Seats:"
          },
          {
            label: "Active Integrations",
            name: LimitsKeys.ActiveIntegrationsLimit,
            fieldType: FieldTypes.NUMBER,
            className: "",
            bubbleMessage: "It is the maximum number of integrations that can be active at the same time.",
            description: "Currently active integrations:"
          },
          {
            label: "Domain Allowlisting",
            name: LimitsKeys.DomainAllowListing,
            fieldType: FieldTypes.ARRAY,
            className: "",
            bubbleMessage: "It is the list of domains that are allowed to use the platform.",
            description: ""
          }
        ],
      }
    ]
  }
];