import { SagaIterator } from "redux-saga";
import { takeLatest, all, takeEvery } from "redux-saga/effects";

import { PackageManagementActionTypes } from "modules/package-management/store/actions/actions";
import {
  fetchPackagesWorker,
  removePackagesWorker,
  createPackageWorker,
  fetchSinglePackageWorker,
  editPackageWorker,
} from "./package-management";

export default function* (): SagaIterator {
  yield all([
    takeLatest(PackageManagementActionTypes.FETCH_PACKAGES, fetchPackagesWorker),
    takeLatest(PackageManagementActionTypes.FETCH_SINGLE_PACKAGE, fetchSinglePackageWorker),
    takeEvery(PackageManagementActionTypes.REMOVE_PACKAGE, removePackagesWorker),
    takeEvery(PackageManagementActionTypes.CREATE_PACKAGE, createPackageWorker),
    takeEvery(PackageManagementActionTypes.EDIT_PACKAGE, editPackageWorker),
  ]);
}
