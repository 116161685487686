import { SagaIterator } from "redux-saga";
import { put, call } from "redux-saga/effects";
import { push } from "connected-react-router";

import { PackageManagementService } from "modules/package-management/services";
import {
  fetchPackagesSuccess,
  fetchPackagesError,
  fetchSinglePackageSuccess,
  fetchSinglePackageError,
  createPackageSuccess,
  createPackageError,
  editPackageSuccess,
  editPackageError,
  removePackageSuccess,
  removePackageError,
} from "modules/package-management/store/actions/actionCreators";
import {
  FetchPackages,
  FetchSinglePackage,
  RemovePackage,
  CreatePackage,
  EditPackage,
} from "modules/package-management/store/actions/actionTypes";

const packageManagementService: PackageManagementService = PackageManagementService.getInstance();

export function* fetchPackagesWorker({ payload }: FetchPackages): SagaIterator {
  try {
    const { data } = yield call(packageManagementService.fetchPackages, payload!.data);
    yield put(fetchPackagesSuccess(data));
  } catch (e) {
    yield put(fetchPackagesError(e.response.data.messages));
  }
}

export function* fetchSinglePackageWorker({ payload }: FetchSinglePackage): SagaIterator {
  try {
    const { data } = yield call(packageManagementService.fetchSinglePackage, payload!.data.packageId);
    yield put(fetchSinglePackageSuccess(data));
  } catch (e) {
    if (e.response.status === 404) {
      yield put(push("/package"));
    }

    yield put(fetchSinglePackageError(e.response.data.messages));
  }
}

export function* removePackagesWorker({ payload }: RemovePackage): SagaIterator {
  try {
    const { data } = yield call(packageManagementService.removePackage, payload!.data.packageId);
    yield put(removePackageSuccess(data));
  } catch (e) {
    yield put(removePackageError(e.response.data.messages));
  }
}

export function* createPackageWorker({ payload }: CreatePackage): SagaIterator {
  try {
    const { data } = yield call(packageManagementService.createPackage, payload!.data.packageData);

    yield put(createPackageSuccess(data));
    yield put(push("/package"));
  } catch (e) {
    yield put(createPackageError(e.response.data.messages));
  }
}

export function* editPackageWorker({ payload }: EditPackage): SagaIterator {
  try {
    const { data } = yield call(packageManagementService.editPackage, payload!.data.packageData);

    yield put(editPackageSuccess(data));
    yield put(push("/package"));
  } catch (e) {
    yield put(editPackageError(e.response.data.messages));
  }
}
