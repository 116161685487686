import { AxiosResponse } from "axios";

import { BaseService } from "libs/services";
import { PaginationLinks, PaginationMeta } from "libs/models";
import { Package } from "modules/package-management/models";

type PackageListData = {
  items: Array<Package>;
  meta: PaginationMeta;
  links: PaginationLinks;
};

type RemovedPackageData = {
  message: string;
  package_id: number;
};

export class PackageManagementService extends BaseService {
  private ADMIN_API_ROUTE = this.ADMIN_API_HOST;

  static _instance: PackageManagementService;

  constructor() {
    super();

    if (PackageManagementService._instance) {
      throw Error("Unable to create the instance!");
    }
  }

  static getInstance(): PackageManagementService {
    if (!PackageManagementService._instance) {
      PackageManagementService._instance = new PackageManagementService();
    }

    return PackageManagementService._instance;
  }

  public fetchPackages = (params: object): Promise<AxiosResponse<PackageListData>> => this.get(`${this.ADMIN_API_ROUTE}/package`, { params });

  public fetchSinglePackage = (packageId: number): Promise<AxiosResponse<Package>> => this.get(`${this.ADMIN_API_ROUTE}/package/${packageId}`);

  public removePackage = (packageId: number): Promise<AxiosResponse<RemovedPackageData>> => this.delete(`${this.ADMIN_API_ROUTE}/package/${packageId}`);

  public createPackage = (packageData: Package): Promise<AxiosResponse<Package>> => this.post(`${this.ADMIN_API_ROUTE}/package`, packageData);

  public editPackage = (packageData: Package): Promise<AxiosResponse<Package>> => this.put(`${this.ADMIN_API_ROUTE}/package/${packageData!.id}`, packageData);
}
