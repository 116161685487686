import { isEmpty } from "lodash";

import { PackageManagement, Package } from "modules/package-management/models";
import { PackageManagementActionTypes } from "modules/package-management/store/actions/actions";
import { PackageManagementActions } from "modules/package-management/store/actions/actionTypes";

const initialState: PackageManagement = {
  isFetching: false,
  errorMessages: [],
  noDataMessage: "",
  packages: [],
  package: null,
  createdPackageId: 0,
  currentPage: 1,
  itemCount: 0,
  itemsPerPage: 15,
  totalItems: 0,
  totalPages: 1,
};

const packageManagementReducer = (state: PackageManagement = initialState, action: PackageManagementActions): PackageManagement => {
  switch (action.type) {
  case PackageManagementActionTypes.FETCH_PACKAGES:
  case PackageManagementActionTypes.FETCH_SINGLE_PACKAGE:
  case PackageManagementActionTypes.REMOVE_PACKAGE:
  case PackageManagementActionTypes.CREATE_PACKAGE:
  case PackageManagementActionTypes.EDIT_PACKAGE: {
    return {
      ...state,
      isFetching: true,
      errorMessages: [],
      noDataMessage: "",
    };
  }

  case PackageManagementActionTypes.FETCH_PACKAGES_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      noDataMessage: isEmpty(action.payload?.items) ? "There are no packages yet!" : "",
      packages: action.payload.items,
      ...action.payload.meta,
    };
  }

  case PackageManagementActionTypes.FETCH_SINGLE_PACKAGE_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      package: action.payload,
    };
  }

  case PackageManagementActionTypes.REMOVE_PACKAGE_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      packages: state.packages.filter((item: Package) => item.id !== Number(action.payload.package_id)),
    };
  }

  case PackageManagementActionTypes.CREATE_PACKAGE_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      packages: [
        ...state.packages,
        action.payload,
      ],
    };
  }

  case PackageManagementActionTypes.EDIT_PACKAGE_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      packages: state.packages.map((item: Package) => item.id === Number(action.payload.id) ? ({ ...item, ...action.payload }) : item),
    };
  }

  case PackageManagementActionTypes.FETCH_PACKAGES_ERROR:
  case PackageManagementActionTypes.FETCH_SINGLE_PACKAGE_ERROR:
  case PackageManagementActionTypes.REMOVE_PACKAGE_ERROR:
  case PackageManagementActionTypes.CREATE_PACKAGE_ERROR:
  case PackageManagementActionTypes.EDIT_PACKAGE_ERROR: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [
        ...state.errorMessages,
        ...action.payload,
      ],
    };
  }

  default:
    return state;
  }
};

export default packageManagementReducer;
