import { Sell } from "modules/up-down-sell/models";
import { UpDownSellsActionTypes } from "./actions";
import * as actionTypes from "./actionTypes";

export const fetchSells = (): actionTypes.FetchSells => ({
  type: UpDownSellsActionTypes.FETCH_SELLS,
});

export const fetchSellsSuccess = (payload: Array<Sell>): actionTypes.FetchSellsSuccess => ({
  type: UpDownSellsActionTypes.FETCH_SELLS_SUCCESS,
  payload,
});

export const fetchSellsError = (payload: Array<string>): actionTypes.FetchSellsError => ({
  type: UpDownSellsActionTypes.FETCH_SELLS_ERROR,
  payload,
});
