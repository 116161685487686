import { SagaIterator } from "redux-saga";
import { takeLatest, takeEvery, all } from "redux-saga/effects";

import { RecommendersActionTypes } from "modules/recommenders/store/actions/actions";
import * as recommendersSaga from "./recommenders-saga";

export default function* (): SagaIterator {
  yield all([
    takeLatest(RecommendersActionTypes.FETCH_RECOMMENDERS, recommendersSaga.fetchRecommendersWorker),
    takeLatest(RecommendersActionTypes.FETCH_SINGLE_RECOMMENDER, recommendersSaga.fetchSingleRecommenderWorker),
    takeEvery(RecommendersActionTypes.CREATE_RECOMMENDER, recommendersSaga.createRecommenderWorker),
    takeEvery(RecommendersActionTypes.EDIT_RECOMMENDER, recommendersSaga.editRecommenderWorker),
    takeEvery(RecommendersActionTypes.SEND_RECOMMENDERS_CONTRACT, recommendersSaga.sendRecommendersContractWorker),
    takeLatest(RecommendersActionTypes.ACTIVATE_RECOMMENDER, recommendersSaga.activateRecommenderWorker),
  ]);
}
