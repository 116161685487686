import { SagaIterator } from "redux-saga";
import { takeLatest, all } from "redux-saga/effects";

import { BotCreatedCountActionTypes } from "modules/bot-created-count/store/actions/actions";
import { fetchAssignedBotsWorker, fetchCreatedBotsWorker } from "./bot-created-count";

export default function* botCreatedCountWatcher(): SagaIterator {
  yield all([
    takeLatest(BotCreatedCountActionTypes.FETCH_CREATED_BOTS, fetchCreatedBotsWorker),
    takeLatest(BotCreatedCountActionTypes.FETCH_ASSIGNED_BOTS, fetchAssignedBotsWorker),
  ]);
}
