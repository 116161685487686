import { SagaIterator } from "redux-saga";
import { all, takeEvery } from "redux-saga/effects";

import { UpDownSellsActionTypes } from "modules/up-down-sell/store/actions/actions";
import { fetchSellsWorker } from "./up-down-sells";

export default function* upDownSellsWatcher(): SagaIterator {
  yield all([
    takeEvery(UpDownSellsActionTypes.FETCH_SELLS, fetchSellsWorker),
  ]);
}
