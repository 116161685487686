import { combineReducers, applyMiddleware, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import { createBrowserHistory } from "history";
import { connectRouter, routerMiddleware } from "connected-react-router";

import authReducer from "modules/auth/store/reducer";
import companyManagementReducer from "modules/company-management/store/reducer";
import freeUsersReducer from "modules/free-users/store/reducer";
import userManagementReducer from "modules/user-management/store/reducer";
import mediaPartnerBotReducer from "modules/media-partner-bot/store/reducer";
import botCreatedCountReducer from "modules/bot-created-count/store/reducer";
import recommendersReducer from "modules/recommenders/store/reducer";
import regionalManagementReducer from "modules/region-management/store/reducer";
import packageManagementReducer from "modules/package-management/store/reducer";
import upDownSellsReducer from "modules/up-down-sell/store/reducer";
import broadcastReducer from "modules/broadcast-issue/store/reducer";
import rootSaga from "./sagas";
import integrationsErrorsReducer from "modules/integrations/store/reducer";

const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  router: connectRouter(history),
  auth: authReducer,
  companyManagement: companyManagementReducer,
  freeUsers: freeUsersReducer,
  userManagement: userManagementReducer,
  mediaPartnerBot: mediaPartnerBotReducer,
  botCreatedCount: botCreatedCountReducer,
  recommenders: recommendersReducer,
  regionalManagement: regionalManagementReducer,
  packageManagement: packageManagementReducer,
  upDownSells: upDownSellsReducer,
  integrationsErrors: integrationsErrorsReducer,
  broadcastIssue: broadcastReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(sagaMiddleware, routerMiddleware(history))
  )
);


sagaMiddleware.run(rootSaga);

export { store, history };
