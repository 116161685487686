import { PaginationLinks, PaginationMeta } from "libs/models";
import { Recommender } from "modules/recommenders/models";
import { RecommendersActionTypes } from "./actions";
import * as actionTypes from "./actionTypes";

export const fetchRecommenders = (page?: number): actionTypes.FetchRecommenders => ({
  type: RecommendersActionTypes.FETCH_RECOMMENDERS,
  payload: {
    data: {
      page,
    },
  },
});

export const fetchRecommendersSuccess = (payload: {
  items: Array<Recommender>;
  meta: PaginationMeta;
  links: PaginationLinks;
}): actionTypes.FetchRecommendersSuccess => ({
  type: RecommendersActionTypes.FETCH_RECOMMENDERS_SUCCESS,
  payload,
});

export const fetchRecommendersError = (payload: Array<string>): actionTypes.FetchRecommendersError => ({
  type: RecommendersActionTypes.FETCH_RECOMMENDERS_ERROR,
  payload,
});

export const fetchSingleRecommender = (recommenderId: number): actionTypes.FetchSingleRecommender => ({
  type: RecommendersActionTypes.FETCH_SINGLE_RECOMMENDER,
  payload: {
    data: {
      recommenderId,
    },
  },
});

export const fetchSingleRecommenderSuccess = (payload: Recommender): actionTypes.FetchSingleRecommenderSuccess => ({
  type: RecommendersActionTypes.FETCH_SINGLE_RECOMMENDER_SUCCESS,
  payload,
});

export const fetchSingleRecommenderError = (payload: Array<string>): actionTypes.FetchSingleRecommenderError => ({
  type: RecommendersActionTypes.FETCH_SINGLE_RECOMMENDER_ERROR,
  payload,
});

export const createRecommender = (data: Recommender): actionTypes.CreateRecommender => ({
  type: RecommendersActionTypes.CREATE_RECOMMENDER,
  payload: {
    data,
  },
});

export const createRecommenderSuccess = (payload: Recommender): actionTypes.CreateRecommenderSuccess => ({
  type: RecommendersActionTypes.CREATE_RECOMMENDER_SUCCESS,
  payload,
});

export const createRecommenderError = (payload: Array<string>): actionTypes.CreateRecommenderError => ({
  type: RecommendersActionTypes.CREATE_RECOMMENDER_ERROR,
  payload,
});

export const editRecommender = (data: Recommender): actionTypes.EditRecommender => ({
  type: RecommendersActionTypes.EDIT_RECOMMENDER,
  payload: {
    data,
  },
});

export const editRecommenderSuccess = (payload: Recommender): actionTypes.EditRecommenderSuccess => ({
  type: RecommendersActionTypes.EDIT_RECOMMENDER_SUCCESS,
  payload,
});

export const editRecommenderError = (payload: Array<string>): actionTypes.EditRecommenderError => ({
  type: RecommendersActionTypes.EDIT_RECOMMENDER_ERROR,
  payload,
});

export const sendRecommendersContract = (recommenderId: number): actionTypes.SendRecommendersContract => ({
  type: RecommendersActionTypes.SEND_RECOMMENDERS_CONTRACT,
  payload: {
    data: {
      recommenderId,
    },
  },
});

export const sendRecommendersContractSuccess = (payload: { message: string }): actionTypes.SendRecommendersContractSuccess => ({
  type: RecommendersActionTypes.SEND_RECOMMENDERS_CONTRACT_SUCCESS,
  payload,
});

export const sendRecommendersContractError = (payload: Array<string>): actionTypes.SendRecommendersContractError => ({
  type: RecommendersActionTypes.SEND_RECOMMENDERS_CONTRACT_ERROR,
  payload,
});

export const closeSuccessMessage = (): actionTypes.CloseSuccessMessage => ({
  type: RecommendersActionTypes.CLOSE_SUCCESS_MESSAGE,
});

export const activateRecommender = ({ recommenderId, token }: { recommenderId: number; token: string }): actionTypes.ActivateRecommender => ({
  type: RecommendersActionTypes.ACTIVATE_RECOMMENDER,
  payload: {
    recommenderId,
    token,
  },
});

export const activateRecommenderSuccess = (successMessage: string): actionTypes.ActivateRecommenderSuccess => ({
  type: RecommendersActionTypes.ACTIVATE_RECOMMENDER_SUCCESS,
  payload: {
    successMessage,
  },
});

export const activateRecommenderError = (payload: Array<string>): actionTypes.ActivateRecommenderError => ({
  type: RecommendersActionTypes.ACTIVATE_RECOMMENDER_ERROR,
  payload,
});
