export enum BotCreatedCountActionTypes {
  FETCH_CREATED_BOTS = "[BOT_CREATED_COUNT_MODULE] FETCH_CREATED_BOTS",
  FETCH_CREATED_BOTS_SUCCESS = "[BOT_CREATED_COUNT_MODULE] FETCH_CREATED_BOTS_SUCCESS",
  FETCH_CREATED_BOTS_ERROR = "[BOT_CREATED_COUNT_MODULE] FETCH_CREATED_BOTS_ERROR",

  FETCH_ASSIGNED_BOTS = "[BOT_CREATED_COUNT_MODULE] FETCH_ASSIGNED_BOTS",
  FETCH_ASSIGNED_BOTS_SUCCESS = "[BOT_CREATED_COUNT_MODULE] FETCH_ASSIGNED_BOTS_SUCCESS",
  FETCH_ASSIGNED_BOTS_ERROR = "[BOT_CREATED_COUNT_MODULE] FETCH_ASSIGNED_BOTS_ERROR",

  CLEAN_ASSIGNED_BOTS = "[BOT_CREATED_COUNT_MODULE] CLEAN_ASSIGNED_BOTS",
}
